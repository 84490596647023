<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-button size="mini" type="primary" @click="onPromotionAdd">
          {{ $tc('add') }}
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" style="width: 100%">
      <el-table-column label width="80" align="center">
        <template slot-scope="scope">
          <el-tooltip
            :content="$tc('delete')"
            class="item"
            effect="dark"
            placement="top-start"
          >
            <el-button
              size="mini"
              icon="el-icon-delete"
              circle
              @click="onPromotionRemove(scope.$index)"
            >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('type')" prop="promotionType" width="220">
        <template slot-scope="scope">
          <el-form-item>
            <select-promotion-type v-model="scope.row.promotionType">
            </select-promotion-type>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('periodsQuantity')"
        prop="promotionPeriodsQuantity"
      >
        <template slot-scope="scope">
          <el-form-item>
            <el-input v-model="scope.row.promotionPeriodsQuantity">
              <template slot="append">
                <el-tooltip :content="$tc('periodQuantityHelp')">
                  <i class="fad fa-question"> </i>
                </el-tooltip>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('percentDiscount')"
        prop="promotionDiscountPercent"
      >
        <template slot-scope="scope">
          <el-form-item>
            <el-input v-model="scope.row.promotionDiscountPercent">
              <template slot="append">
                <i class="fad fa-percentage"> </i>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { promotion as promotionModel } from './model'

export default {
  name: 'Promotion',
  props: {
    list: {
      defeault: [],
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    onPromotionAdd() {
      // console.log('%c⧭', 'color: #ff6600', this.list)
      this.list.push(promotionModel())
    },
    onPromotionRemove(index) {
      this.list.splice(index, 1)
    },
  },
}
</script>
